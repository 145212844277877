
import type UserService from '@/modules/user/user.service';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class DMSPage extends Vue {
    @inject(KEY.UserService) userService!: UserService;

    get queryParams() {
        const params = {
            fornovaId: this.userService.currentHotelId,
        };

        return Object
            .entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
    }
}
